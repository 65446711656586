@import 'styles/includes.nordr.scss';

.BasePage {
    &--Wrapper {
        margin: 0 auto;
        background-color: $colorBrownLight20;
    }

    &--Layout-our-homes {
        background-image: $backgdImgGradientLeft;
        background-repeat: no-repeat;
        background-size: 100% 800px;
    }
}
